import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ForceNav from "./ForceNav";

const ForceHero = () => {
  return (
    <>
      <header className="d-flex flex-column">
        <ForceNav />
        <div className="landing_hero flex-grow-1 py-4 py-xl-0 d-flex align-items-center">
          <Container className="py-5 text-center text-md-start">
            <Row>
              <Col md={8} lg={7} xl={6} data-aos="fade" data-aos-duration="800">
                <h1
                  data-aos="fade-down"
                  data-aos-duration="800"
                  className="fs_6xl fw_bold ff_oswald text-black mb-3"
                >
                  Specializing in
                  <span className="text_secondary"> Welding</span>,
                  <span className="text_secondary"> Fabrication </span>
                  and <span className="text_secondary"> Engineering </span>
                  Services
                </h1>
                <p
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="200"
                  className="common_para mb-0"
                >
                  Equipped with a high calibre workshop and an Australia-Wide
                  Labour Hire team, we offer a broad range of services including
                  Erection, Pipework, Comprehensive Support and More.
                </p>

                <a
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="400"
                  className="secondary_btn ff_noto fw_bold d-inline-block   mt-sm-5 mt-4"
                  href="#"
                >
                  Learn more
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default ForceHero;
