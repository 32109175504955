import React from "react";
import Warehouse_img1 from "../assets/image/png/Warehouse_img1.png";
import Warehouse_img2 from "../assets/image/png/Warehouse_img2.png";
import Warehouse_img3 from "../assets/image/png/Warehouse_img3.png";
import Warehouse_img4 from "../assets/image/png/Warehouse_img4.png";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Workshop() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    slidesToShow: 3.3,
    pauseOnHover: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section id="Workshop" className="py-5 overflow-hidden">
        <div className="container">
          <h2
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="2000"
            className="common_heading text_primary m-0 pt-3 pt-sm-5 pb-xl-3"
          >
            Warehouse workshop
          </h2>
          <div
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-duration="2000"
            className="workshop_slider "
          >
            <Slider {...settings} className="mb-3 mb-xl-5 mt-5">
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100 rounded-1"
                    src={Warehouse_img1}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img1}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1"
                    src={Warehouse_img2}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img2}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1"
                    src={Warehouse_img3}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img3}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1"
                    src={Warehouse_img4}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img4}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1 "
                    src={Warehouse_img1}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img1}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1"
                    src={Warehouse_img2}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img2}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1"
                    src={Warehouse_img3}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img3}
                  />
                </div>
              </div>
              <div>
                <div className="px-2">
                  <LazyLoadImage
                    className="w-100 h-100  rounded-1"
                    src={Warehouse_img4}
                    alt="Warehouse_img1"
                    effect="blur"
                    placeholderSrc={Warehouse_img4}
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}

export default Workshop;
