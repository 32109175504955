import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery from "./pages/Gallery";
import OurServices from "./pages/OurServices";
import HomePage from "./pages/HomePage";
import { Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BackToTop from "./components/BackToTop";
function App() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);
  return (
    <>
      <div className="overflow_x_hidden">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Projects" element={<Gallery />} />
          <Route path="/Service" element={<OurServices />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <BackToTop />
      </div>
    </>
  );
}

export default App;
