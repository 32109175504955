import React from "react";
const HerosectionProjects = () => {
  return (
    <>
      <section className="gallery_background min_65_vh d-flex flex-column">
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 pb-5 ">
          <div className="container pb-5">
            <h2
              className=" ff_oswald fw_bold text-white fs_6xl mb-3 text-center"
              data-aos="fade-down"
              data-aos-duration="2000"
              data-aos-offset="100"
            >
              Welcome to our Projects section
            </h2>
            <p
              className="common_para text-white mb-0 text-center opacity-100"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-offset="100"
            >
              where you can explore the innovative solutions we have developed
              for clients across a range of industries.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default HerosectionProjects;
