import React from "react";
import vector from "../assets/image/png/vector.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const AboutUs = () => {
  return (
    <>
      <section className="py-5" id="About">
        <div className="container pb-lg-5">
          <div className="row align-items-center justify-content-center justify-content-lg-between">
            <div className="col-sm-10 col-md-8  col-lg-6">
              <div className="text-center pe-xl-4 up-down-anim">
                <div
                  data-aos="flip-left"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  <LazyLoadImage
                    placeholderSrc={vector}
                    effect="blur"
                    className="w-100 mb-3 "
                    src={vector}
                    alt="vector"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-center text-lg-start ps-xl-5">
                <div
                  data-aos="fade-down-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  <h2 className="common_heading text_secondary mb-3">
                    About us
                  </h2>

                  <p className="common_para mb-0  ">
                    Established over two decades ago, First Force Pty Ltd has
                    built a reputation as a leading provider of steel
                    fabrication services in Australia. With extensive experience
                    serving a range of industries, including construction,
                    mining, and government infrastructure, our team has the
                    expertise to handle even the most complex projects.
                  </p>
                </div>
                <div
                  data-aos="fade-up-left"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  <p className="common_para mb-0 mt-3 ">
                    From engineering and fabrication to erection and
                    installation, we are equipped to handle all aspects of steel
                    fabrication. Our commitment to quality is evident in every
                    project we undertake, from the use of top-notch materials to
                    the expertise of our team. We understand the importance of
                    delivering projects on time and with integrity, and we work
                    tirelessly to ensure that each project is completed to the
                    highest standards.
                  </p>
                  <div
                    data-aos="fade-down-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    data-aos-offset="200"
                  >
                    <p className="common_para mb-0 mt-3 ">
                      Whether it's building a bridge, fabricating pressure
                      vessels, or providing engineering services, we have the
                      skills and experience to get the job done right. Trust
                      First Force Pty Ltd to provide the quality steel
                      fabrication services you need, on time and with integrity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
