export const TwitterVector = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.82098 0 0 5.82098 0 13C0 20.179 5.82098 26 13 26C20.179 26 26 20.179 26 13C26 5.82098 20.179 0 13 0ZM19.2475 9.79933C19.2562 9.93571 19.2562 10.0779 19.2562 10.2172C19.2562 14.477 16.0121 19.3839 10.0837 19.3839C8.25558 19.3839 6.56094 18.8529 5.13326 17.9388C5.39442 17.9679 5.64397 17.9795 5.91094 17.9795C7.41987 17.9795 8.80692 17.4688 9.9125 16.604C8.49643 16.575 7.3067 15.6464 6.90045 14.3696C7.39665 14.4422 7.84353 14.4422 8.35424 14.3116C7.6251 14.1635 6.96973 13.7675 6.49947 13.1909C6.02921 12.6143 5.77306 11.8927 5.77455 11.1487V11.108C6.20112 11.3489 6.70312 11.4969 7.22835 11.5172C6.78682 11.2229 6.42472 10.8243 6.17416 10.3566C5.9236 9.88885 5.79232 9.36654 5.79196 8.83594C5.79196 8.23527 5.94866 7.68683 6.23013 7.21094C7.03945 8.20723 8.04935 9.02206 9.19421 9.60249C10.3391 10.1829 11.5932 10.5159 12.8752 10.5799C12.4196 8.38906 14.0562 6.61607 16.0237 6.61607C16.9522 6.61607 17.7879 7.00491 18.377 7.6317C19.1054 7.49531 19.8018 7.22254 20.4228 6.85692C20.1819 7.60268 19.677 8.23237 19.0067 8.62991C19.6567 8.56027 20.2835 8.38036 20.8638 8.1279C20.4257 8.7721 19.8772 9.34375 19.2475 9.79933Z"
        fill="#FF9400"
      />
    </svg>
  );
};
