import React from "react";
import ContactUsTwo from "../components/ContactUsTwo";
import Footer from "../components/Footer";
import NowContact from "../components/NowContact";

const Contact = () => {
  return (
    <>
      <NowContact />
      <ContactUsTwo />
      <Footer />
    </>
  );
};

export default Contact;
