import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ForceNav from "./ForceNav";
import { TelegramVector } from "./TelegramVector";
import { TwitterVector } from "./TwitterVector";
const NowContact = () => {
  const [openNav, setopenNav] = useState(true);
  if (openNav) {
    document.body.classList.remove("overflow-hidden");
  } else {
    document.body.classList.add("overflow-hidden");
  }
  return (
    <>
      <section>
        <ForceNav />
        <div className="Now_contact_bg_img mh_60 d-flex flex-column">
          <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 ">
            <div className="container">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h2
                  className=" ff_oswald fw_bold text-white fs_6xl mb-3"
                  data-aos="fade-down"
                  data-aos-duration="800"
                >
                  Contact us now
                </h2>
                <p
                  className="fs_base text-white fw_reular ff_noto text-white mb-0 text-center "
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  Get in Touch with Us Today to Discuss Your Requirements or
                  Enquiries
                </p>{" "}
              </div>
            </div>
          </div>
          <Container>
            <div className=" mt-3 d-flex align-items-end flex-column gap-3 mb-5">
              <a
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-offset="200"
                href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"
                target={"_blank"}
                className=" d-inline-block transform_scale transition_linear"
              >
                <TwitterVector />
              </a>
              <a
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-offset="200"
                className=" d-inline-block transform_scale transition_linear"
                href="https://web.telegram.org/z/"
                target={"_blank"}
              >
                <TelegramVector />
              </a>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default NowContact;
