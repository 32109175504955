import React from "react";
import ForceHero from "../components/ForceHero";
import AboutUs from "../components/AboutUs";
import Service from "../components/Service";
import Footer from "../components/Footer";
import ContactUsTwo from "../components/ContactUsTwo";
import Testimonials from "../components/Testimonials";
const HomePage = () => {
  return (
    <>
      <ForceHero />
      <AboutUs />
      <Service />
      <Testimonials />
      <ContactUsTwo />
      <Footer />
    </>
  );
};

export default HomePage;
