export const PhoneVector= () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.97507C16 7.03047 15.3207 5.38072 13.962 4.02582C12.604 2.67158 10.95 1.99446 9 1.99446V0C10.25 0 11.421 0.236676 12.513 0.710028C13.6043 1.18404 14.5543 1.82427 15.363 2.63069C16.171 3.43645 16.8127 4.38382 17.288 5.4728C17.7627 6.56111 18 7.72853 18 8.97507H16ZM12 8.97507C12 8.14404 11.7083 7.43767 11.125 6.85596C10.5417 6.27424 9.83333 5.98338 9 5.98338V3.98892C10.3833 3.98892 11.5627 4.4749 12.538 5.44687C13.5127 6.4195 14 7.59557 14 8.97507H12ZM16.95 18C14.8 18 12.7043 17.522 10.663 16.566C8.621 15.6106 6.81267 14.3475 5.238 12.7765C3.66267 11.2062 2.396 9.40288 1.438 7.36654C0.479334 5.33086 0 3.241 0 1.09695C0 0.797784 0.0999999 0.548476 0.3 0.34903C0.5 0.149584 0.75 0.0498614 1.05 0.0498614H5.1C5.33333 0.0498614 5.54167 0.124654 5.725 0.274238C5.90833 0.423823 6.01667 0.614958 6.05 0.847645L6.7 4.33795C6.73333 4.57064 6.72933 4.78238 6.688 4.97319C6.646 5.16465 6.55 5.33518 6.4 5.48476L4 7.92798C4.7 9.12465 5.575 10.2465 6.625 11.2936C7.675 12.3407 8.83333 13.2465 10.1 14.0111L12.45 11.6676C12.6 11.518 12.796 11.4057 13.038 11.3305C13.2793 11.2561 13.5167 11.2355 13.75 11.2687L17.2 11.9668C17.4333 12.0166 17.625 12.1286 17.775 12.3028C17.925 12.4777 18 12.6814 18 12.9141V16.9529C18 17.2521 17.9 17.5014 17.7 17.7008C17.5 17.9003 17.25 18 16.95 18Z"
        fill="#FF9400"
      />
    </svg>
  );
};
