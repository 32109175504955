import "aos/dist/aos.css";

import Hire from "../components/Hire";
import Fabricate from "../components/Fabricate";
import Footer from "../components/Footer";
import ForceNav from "../components/ForceNav";
import Herosection from "../components/Herosection";
import ContactUsTwo from "../components/ContactUsTwo";
const OurServices = () => {
  return (
    <>
      <ForceNav />
      <Herosection />

      <Hire />
      <Fabricate />
      <ContactUsTwo />
      <Footer />
    </>
  );
};

export default OurServices;
