import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import untiled_atwork from "../assets/image/png/Untitled_Artwork_logo.png";
import { EmailVector } from "./EmailVector";
import { PhoneVector } from "./PhoneVector";
import { TelegramVector } from "./TelegramVector";
import { TwitterVector } from "./TwitterVector";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Footer = () => {
  return (
    <>
      <footer className="bg_img_footer position-relative pt-4 pb-2">
        <Container className="py-5">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="3">
              <LazyLoadImage
                data-aos="fade-down"
                data-aos-duration="800"
                className="pt-4 cursor_pointer"
                src={untiled_atwork}
                placeholderSrc={untiled_atwork}
                alt="untiled_atwork"
                effect="blur"
              />
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className=" ff_noto fw-normal text-white fs_base mb-0"
              >
                Address
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className=" ff_noto fw-normal text-white fs_base"
              >
                81 Riverstone Parade, Riverstone NSW 2765 Australia
              </p>
            </Col>
            <Col xl="5" lg={7} className="mt-sm-3">
              <div className="footer_li">
                <Row className=" mt-3">
                  <Col xs={6} sm={4}>
                    <ul
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="100"
                      className=" mt-5 ps-0"
                    >
                      <li className="ff_noto fw-normal text-white fs_base">
                        Quick links
                      </li>
                      <li className=" mt-3">
                        <a
                          href="#"
                          className="ff_noto fw-normal text-white fs_base"
                        >
                          Home
                        </a>
                      </li>
                      <li className=" mt-3">
                        <a
                          href="#"
                          className="ff_noto fw-normal text-white fs_base"
                        >
                          About us
                        </a>
                      </li>
                      <li className=" mt-3">
                        <a
                          href="#"
                          className="ff_noto fw-normal text-white fs_base"
                        >
                          Service
                        </a>
                      </li>
                      <li className=" mt-3">
                        <a
                          href="#"
                          className="ff_noto fw-normal text-white fs_base"
                        >
                          Gallery
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} sm={4}>
                    <ul
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="200"
                      className=" mt-5 ps-0"
                    >
                      <li className=" ff_noto fw-normal  text-white fs_base">
                        Contacts
                      </li>
                      <li className=" mt-3">
                        <a
                          href="tel:123-433-899"
                          className=" ff_noto fw-normal  text-white fs_base"
                        >
                          <PhoneVector /> <span>123-433-899</span>
                        </a>
                      </li>
                      <li className=" mt-3">
                        <a
                          href="tel:2238-88-888"
                          className=" ff_noto fw-normal  text-white fs_base"
                        >
                          <PhoneVector />
                          <span className=" ps-2">2238-88-888</span>
                        </a>
                      </li>
                      <li className=" mt-3">
                        <a
                          href="mailto: @firstforce.in"
                          className=" ff_noto fw-normal  text-white fs_base"
                        >
                          <EmailVector />
                          <span className="ps-2">@firstforce.in</span>
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} sm={4}>
                    <ul
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="300"
                      className=" mt-5 ps-0"
                    >
                      <li className="ff_noto fw-normal text-white fs_base">
                        Privacy police
                      </li>
                      <li className=" mt-3">
                        <a
                          href="#"
                          className=" ff_noto fw-normal text-white fs_base"
                        >
                          Terms
                        </a>
                      </li>
                      <li className=" mt-3">
                        <a
                          href="#"
                          className=" ff_noto fw-normal text-white fs_base"
                        >
                          Help Centre
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className=" mt-3 d-sm-flex justify-content-center justify-content-lg-end gap-1 me-lg-5 pe-lg-5">
                <a
                  data-aos="zoom-in"
                  data-aos-duration="900"
                  data-aos-offset="200"
                  data-aos-delay="300"
                  href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"
                  target={"_blank"}
                  className=" me-2"
                >
                  <TwitterVector />
                </a>
                <a
                  data-aos="zoom-in"
                  data-aos-duration="900"
                  data-aos-offset="200"
                  data-aos-delay="400"
                  href="https://web.telegram.org/z/"
                  target={"_blank"}
                >
                  <TelegramVector />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="line_footer"></div>
        <p className="ff_noto fw-normal opacity_07 text-white fs_sm text-center">
          Copyright@2022|FIRSTFORCE
        </p>
      </footer>
    </>
  );
};

export default Footer;
