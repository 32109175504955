import React, { Component } from "react";
import Slider from "react-slick";
import welding from "../assets/image/png/welding.png";
import workshop from "../assets/image/png/workshop.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplaySpeed: 1500,
      // autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <section className="service_background  py-5 overflow-hidden">
          <div className="container">
            <h2 className="common_heading text-white text-center">
              Our <span className="text_secondary ms-2">Services</span>
            </h2>
            <p className="mb-0 ff_noto fw_regular fs_base text-white text-center mt-3">
              Expertly Providing a Wide Range of Solutions to Meet Your Needs.
            </p>
            <div className="py-sm-5 mt-4">
              <Slider {...settings} className="landing_slider">
                <div className=" px-3">
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="200"
                    className="shadow d-flex flex-column justify-content-between align-items-baseline p-4 h-100"
                  >
                    <div>
                      <LazyLoadImage
                        placeholderSrc={welding}
                        effect="blur"
                        className="w-100  "
                        src={welding}
                        alt="welding"
                      />
                      <h2 className="ff_noto fw_semibold fs_2xl text-black mt-3">
                        Labour Hire
                      </h2>
                      <p className="mb-4 common_para opacity_07">
                        First Force Pty Ltd is a premier labour hire company
                        that is dedicated to providing exceptional services in
                        welding, engineering, and fabrication, as well as
                        general trade support.
                      </p>
                    </div>

                    <a
                      className="secondary_btn ff_noto fw_bold d-inline-block "
                      href="#"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                  className=" px-3"
                >
                  <div className="shadow d-flex flex-column justify-content-between align-items-baseline p-4 h-100">
                    <div>
                      {" "}
                      <LazyLoadImage
                        placeholderSrc={workshop}
                        effect="blur"
                        className="w-100  "
                        src={workshop}
                        alt="workshop"
                      />
                      <h2 className="ff_noto fw_semibold fs_2xl text-black mt-3">
                        Workshop
                      </h2>
                      <p className="mb-4 common_para opacity_07">
                        Our workshop factory is designed to meet the needs of
                        even the most demanding projects
                      </p>{" "}
                    </div>
                    <a
                      className="secondary_btn ff_noto fw_bold d-inline-block "
                      href="#"
                    >
                      Learn more
                    </a>{" "}
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                  className=" px-3"
                >
                  <div className="shadow d-flex flex-column justify-content-between align-items-baseline p-4 h-100">
                    <div>
                      <LazyLoadImage
                        placeholderSrc={welding}
                        effect="blur"
                        className="w-100  "
                        src={welding}
                        alt="welding"
                      />
                      <h2 className="ff_noto fw_semibold fs_2xl text-black mt-3">
                        Labour Hire
                      </h2>
                      <p className="mb-4 common_para opacity_07">
                        First Force Pty Ltd is a premier labour hire company
                        that is dedicated to providing exceptional services in
                        welding, engineering, and fabrication, as well as
                        general trade support.
                      </p>
                    </div>
                    <a
                      className="secondary_btn ff_noto fw_bold d-inline-block "
                      href="#"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </>
    );
  }
}
