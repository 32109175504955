export const EmailVector = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 18L15.33 16.5176L16.9837 14.8235H12.6V12.7059H16.9837L15.33 11.0118L16.8 9.52941L21 13.7647L16.8 18ZM2.1 14.8235C1.5225 14.8235 1.0283 14.6164 0.6174 14.202C0.2058 13.7869 0 13.2882 0 12.7059V2.11765C0 1.53529 0.2058 1.03659 0.6174 0.621529C1.0283 0.207176 1.5225 0 2.1 0H15.75C16.3275 0 16.8221 0.207176 17.2337 0.621529C17.6445 1.03659 17.85 1.53529 17.85 2.11765V7.51765C17.675 7.48235 17.5 7.45588 17.325 7.43824C17.15 7.42059 16.975 7.41177 16.8 7.41177C16.625 7.41177 16.45 7.416 16.275 7.42447C16.1 7.43365 15.925 7.45588 15.75 7.49118V3.6L8.82 8.47059L2.1 3.62647V12.7059H10.5787C10.5437 12.8824 10.5217 13.0588 10.5126 13.2353C10.5042 13.4118 10.5 13.5882 10.5 13.7647C10.5 13.9412 10.5087 14.1176 10.5262 14.2941C10.5437 14.4706 10.57 14.6471 10.605 14.8235H2.1ZM3.6225 2.11765L8.82 5.87647L14.175 2.11765H3.6225Z"
        fill="#FF9400"
      />
    </svg>
  );
};
