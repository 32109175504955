import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import fabricate from "../assets/image/png/fabricate.png";
const Fabricate = () => {
  return (
    <>
      <section className="background_engin py-md-5" id="fabricate">
        <div className="container">
          <div className="flex-column-reverse flex-lg-row row align-items-center justify-content-between py-5">
            <div className="col-lg-6">
              <div className="text-center text-lg-start ps-md-3">
               <div
                  data-aos="fade-up-right"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  <h2 className="common_heading text-white mt-3">Workshop</h2>
                  <p className=" common_para text-white mb-0  mt-4">
                    Our workshop factory is designed to meet the needs of even
                    the most demanding projects. With overhead cranes and
                    spacious dispatch areas, we have the capacity to manage
                    multiple large projects simultaneously, ensuring efficiency
                    and flexibility. To ensure the quality of our work, we have
                    the ability to conduct trial assemblies within our yard and
                    provide surface treatment services, including blasting and
                    painting, to ensure a polished finish for all of our
                    clients' products
                  </p>
                </div>
                <div
                  ANCHOR
                  PLACEMENT
                  data-aos-duration="1000"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="200"
                >
                  <p className=" common_para text-white mb-0  mt-3">
                    Having access to the latest tools and technology, along with
                    ample space and resources, allows us to consistently deliver
                    high-quality results that meet or exceed client
                    expectations. Whether it's a large-scale construction
                    project or a specialized fabrication need, our workshop
                    factory is equipped to handle it all with precision,
                    efficiency, and expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-8  col-lg-6 ">
              <div
                data-aos="zoom-in-left"
                data-aos-duration="1000"
                data-aos-offset="200"
              >
                <div className="text-center">
                  <LazyLoadImage
                    placeholderSrc={fabricate}
                    effect="blur"
                    className="w-100 mb-3 "
                    src={fabricate}
                    alt="fabricate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fabricate;
