export const TelegramVector = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.81813 0 0 5.81897 0 13C0 20.181 5.81897 26 13 26C20.1819 26 26 20.181 26 13C26 5.81897 20.181 0 13 0ZM19.3851 8.90626L17.2514 18.9607C17.0937 19.6736 16.6694 19.8464 16.0772 19.5109L12.8272 17.1155L11.2597 18.6252C11.0869 18.798 10.9401 18.9448 10.6046 18.9448L10.8353 15.6369L16.8581 10.1954C17.1206 9.96471 16.8002 9.83387 16.4538 10.0645L9.01026 14.7504L5.80219 13.749C5.10523 13.5292 5.08929 13.052 5.94897 12.7165L18.4826 7.88303C19.0647 7.67335 19.573 8.02477 19.3843 8.90542L19.3851 8.90626Z"
        fill="#FF9400"
      />
    </svg>
  );
};
