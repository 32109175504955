import React from "react";
import hire from "../assets/image/png/hire.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Hire = () => {
  return (
    <>
      <section className="py-md-5" id="hire">
        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-lg-between py-5">
            <div className="col-sm-10 col-md-8  col-lg-6">
              {" "}
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                data-aos-offset="200"
              >
                <div className="text-center text-lg-start ">
                  {" "}
                  <LazyLoadImage
                    placeholderSrc={hire}
                    effect="blur"
                    className="w-100 mb-3 "
                    src={hire}
                    alt="hire"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-6  ">
              <div className="text-center text-lg-start">
                <div
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  <h2 className="common_heading  ">
                    Labour <span className="text_secondary">Hire</span>
                  </h2>
                  <p className="common_para mt-3 mb-0">
                    First Force Pty Ltd is a premier labour hire company that is
                    dedicated to providing exceptional services in welding,
                    engineering, and fabrication, as well as general trade
                    support. Our highly skilled and experienced professionals
                    bring a wealth of expertise to each project, delivering
                    top-quality workmanship and unparalleled service
                  </p>
                </div>
                <div
                  data-aos="flip-left"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  <p className="common_para mt-3 mb-0">
                    We are fully committed to maintaining the highest standards
                    of safety and professionalism, which is why we manage our
                    own Super and Worker's Compensation insurances and
                    liabilities. This means that our clients can trust us to
                    take care of all aspects of the project, from start to
                    finish, while ensuring the well-being of our employees and
                    the safety of the work environment.
                  </p>{" "}
                  <p className="common_para mt-3 mb-0">
                    With a focus on meeting and exceeding client expectations,
                    First Force Pty Ltd is the ideal choice for those seeking a
                    reliable and experienced labour hire company. Whether you
                    are a large construction company, a government
                    infrastructure project, or a small-scale operation, we have
                    the skills, experience, and commitment to deliver
                    outstanding results.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hire;
