import React, { useRef } from "react";
import Slider from "react-slick";
import dis_max from "../assets/image/svg/dis_max.svg";
import star from "../assets/image/svg/stars.svg";
import left_dot from "../assets/image/svg/left_dot.svg";
import right_dot from "../assets/image/svg/right_dot.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SLProfile from "../assets/image/png/SandL.png";
import SkrobarImg from "../assets/image/png/skrobar.png";
import tankVision from "../assets/image/png/tank-vision.png";
import Dyss from "../assets/image/png/Dyss.png";
import Mikhel from "../assets/image/png/Mikhel.png";
import colsee from "../assets/image/png/colsee.png";
function Testimonials() {
  const slider = useRef(null);
  var settings = {
    centerMode: true,
    centerPadding: "300px",
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerPadding: "10px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="py-5 overflow-hidden">
      <div className="container">
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-duration="2000"
          className="py-4 py-md-5"
        >
          <h2 className="common_heading text_secondary text-center m-0">
            Testimonials
          </h2>
        </div>
        <div
          data-aos="zoom-out"
          data-aos-offset="100"
          data-aos-duration="2000"
          className="Testimonials_slider"
        >
          <Slider ref={slider} {...settings} className="mt-5">
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <div className="rounded-2">
                    <img width={80} src={colsee} alt="dis_max" />
                  </div>
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Richard
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      Coslee Australia Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    I have been consistently impressed with the quality of work
                    and professionalism of the team at First Force Pty Ltd.
                    Their attention to detail in steel fabrication and labour
                    hire and commitment to delivering on time and within budget
                    is outstanding.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <div className="overflow-hidden rounded-2">
                    <img width={60} src={dis_max} alt="dis_max" />
                  </div>
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Mitchell
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      Libratum Consulting Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    I recently hired First Force Pty Ltd for a large steel
                    fabrication and welding project, and I was extremely
                    satisfied with the results. Their skilled workforce and
                    state-of-the-art facilities enabled them to deliver
                    exceptional results that exceeded my expectations.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <img width={120} src={SkrobarImg} alt="SkrobarImg" />
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Craig
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      Skrobar Engineering Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    Working with First Force Pty Ltd was a pleasure from start
                    to finish. Their team was knowledgeable, responsive, and
                    dedicated to delivering a top- quality product. I highly
                    recommend their services.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <div className="d-inline-block testimonial_profile">
                    <img width={120} src={tankVision} alt="tankVision" />
                  </div>
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Nikki
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      Tank Vision Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    I have used First Force for several projects, and they
                    consistently deliver excellent results. Their expertise in
                    steel fabrication and commitment to customer satisfaction
                    makes them my go-to-choice for labour hire when needed.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <div className="overflow-hidden rounded-2">
                    <img width={120} src={Mikhel} alt="Mikhel" />
                  </div>
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Michael
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      Mikhel Scaffolding Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    The team at First Force Pty Ltd is professional, skilled,
                    and efficient. They completed my project ahead of schedule
                    and the end result was of the highest quality. I would
                    highly recommend their services to anyone in need of steel
                    fabrication, welding and labour hire.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <div className="overflow-hidden rounded-2">
                    <img width={60} src={dis_max} alt="dis_max" />
                  </div>
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Taylan
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      A & T Varol Painting Services Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    Choosing First Force Pty Ltd was a wise decision for my
                    project. Their team of experts provided top-notch
                    fabrication services, and their dedication to customer
                    satisfaction was evident throughout the process. I would not
                    hesitate to work with them again.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <div className="overflow-hidden rounded-2">
                    <img width={90} src={Dyss} alt="Dyss" />
                  </div>
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Dylan
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      Dyss Events.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    The quality of work and level of professionalism displayed
                    by First Force Pty Ltd was exceptional. Their team worked
                    tirelessly to ensure that my large-scale installation
                    project was completed to my specifications and delivered on
                    time. I highly recommend their services.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
            <div className="px-2">
              <div className="box1 h-100">
                <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-4 align-items-sm-center align-items-baseline">
                  <img width={60} src={SLProfile} alt="SLProfile" />
                  <div>
                    <h4 className="ff_noto fw_bold fs_md text-black m-0">
                      Jamie
                    </h4>
                    <p className="common_para m-0 pt-1 opacity-100">
                      S&L Steel Pty Ltd.
                    </p>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <span className="pt-1 pe-1">
                    <LazyLoadImage
                      src={left_dot}
                      alt="left_dot"
                      effect="blur"
                      placeholderSrc={left_dot}
                    />
                  </span>
                  <p className="common_para pt-2 m-0">
                    First Force Pty Ltd exceeded my expectations. From their
                    prompt and courteous customer service to their expert
                    craftsmanship, I was thoroughly impressed. I would not
                    hesitate to use their services again for future projects.
                  </p>
                  <span className="d-flex flex-column pb-2 justify-content-end">
                    <LazyLoadImage
                      src={right_dot}
                      alt="right_dot"
                      effect="blur"
                      placeholderSrc={right_dot}
                    />
                  </span>
                </div>
                <LazyLoadImage
                  className="ms-3 testimonials_rating mt-3"
                  src={star}
                  width={100}
                  alt="star"
                  effect="blur"
                  placeholderSrc={star}
                />
              </div>
            </div>
          </Slider>
        </div>
        <div className="d-flex justify-content-center mt-2 mt-sm-5 gap-3">
          <button
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="2000"
            className="border-0 left_arrow"
            onClick={() => slider.current.slickPrev()}
          >
            <svg
              width="11"
              height="22"
              viewBox="0 0 11 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.41935 21.0844L0.266129 11.7757C0.169354 11.6649 0.100967 11.5449 0.0609675 11.4156C0.0203223 11.2863 0 11.1478 0 11C0 10.8522 0.0203223 10.7137 0.0609675 10.5844C0.100967 10.4551 0.169354 10.3351 0.266129 10.2243L8.41935 0.887863C8.64516 0.629287 8.92742 0.5 9.26613 0.5C9.60484 0.5 9.89516 0.638522 10.1371 0.915567C10.379 1.19261 10.5 1.51583 10.5 1.88522C10.5 2.25462 10.379 2.57784 10.1371 2.85488L3.02419 11L10.1371 19.1451C10.3629 19.4037 10.4758 19.7221 10.4758 20.1004C10.4758 20.4794 10.3548 20.8074 10.1129 21.0844C9.87097 21.3615 9.58871 21.5 9.26613 21.5C8.94355 21.5 8.66129 21.3615 8.41935 21.0844Z"
                fill="white"
              />
            </svg>
          </button>
          <button
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-offset="100"
            className="border-0 right_arrow"
            onClick={() => slider.current.slickNext()}
          >
            <svg
              width="11"
              height="22"
              viewBox="0 0 11 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.58065 21.0844L10.7339 11.7757C10.8306 11.6649 10.899 11.5449 10.939 11.4156C10.9797 11.2863 11 11.1478 11 11C11 10.8522 10.9797 10.7137 10.939 10.5844C10.899 10.4551 10.8306 10.3351 10.7339 10.2243L2.58065 0.887863C2.35484 0.629287 2.07258 0.5 1.73387 0.5C1.39516 0.5 1.10484 0.638522 0.862903 0.915567C0.620968 1.19261 0.5 1.51583 0.5 1.88522C0.5 2.25462 0.620968 2.57784 0.862903 2.85488L7.97581 11L0.862903 19.1451C0.637097 19.4037 0.524193 19.7221 0.524193 20.1004C0.524193 20.4794 0.645161 20.8074 0.887097 21.0844C1.12903 21.3615 1.41129 21.5 1.73387 21.5C2.05645 21.5 2.33871 21.3615 2.58065 21.0844Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
