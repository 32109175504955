import React from "react";
import ForceNav from "../components/ForceNav";
import HerosectionProjects from "../components/HreosectionProjects";
import Workshop from "../components/Workshop";
import Footer from "../components/Footer";
import ContactUsTwo from "../components/ContactUsTwo";
const Gallery = () => {
  return (
    <>
      <ForceNav />
      <HerosectionProjects />
      <Workshop />
      <ContactUsTwo />
      <Footer />
    </>
  );
};

export default Gallery;
