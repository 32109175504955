import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import workman_us from "../assets/image/png/workman_pointing_us.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const ContactUsTwo = () => {
  return (
    <>
      <section className="mt-md-2 py-4 bg_img_contact">
        <Container className="mt-5 my-sm-5">
          <Row className="align-items-center flex-column-reverse flex-md-row">
            <Col md="6" lg="6" className="mt-4 mt-md-0">
              <h2
                data-aos="fade-right"
                data-aos-duration="800"
                className=" fs_4xl fw_semibold ff_oswald text_secondary mt-sm-4"
              >
                Contact <span className=" text-black">us </span>{" "}
              </h2>
              <p
                data-aos="fade-right"
                data-aos-duration="800"
                className=" fw-normal fs_base ff_noto opacity_07 Contact_us_para pt-1"
              >
                Get in Touch with Us Today to Discuss Your Requirements or
                Enquiries.
              </p>

              <form className="w-100 mt-5">
                <Row>
                  <Col xs={12} sm={6}>
                    <input
                      required
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="300"
                      className="ps-3 pe-2"
                      type="text"
                      placeholder="Full name"
                      id="placeholder_boder"
                    />
                  </Col>
                  <Col xs={12} sm={6} className="pt-4 pt-sm-0">
                    <input
                      required
                      className="w-100 ps-3"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="400"
                      type="email"
                      placeholder="E-mail"
                      id="placeholder_boder"
                    />
                  </Col>
                  <Col xs={12} className="pt-4">
                    <input
                      required
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="300"
                      className="ps-3 pe-2"
                      type="number"
                      min="0"
                      max="20"
                      placeholder="Phone"
                      id="placeholder_boder"
                    />
                  </Col>

                  <Col xs={12} className="pt-4">
                    <textarea
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="400"
                      className="ps-3 pe-2"
                      placeholder="Message"
                      rows="5"
                    ></textarea>
                  </Col>
                  {/* <Col xs={12} sm={6} className="pt-4">
                    <input
                      className="w-100 ps-3"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="400"
                      type="text"
                      placeholder="Address"
                      id="placeholder_boder"
                    />
                  </Col>
                  <Col xs={12} sm={6} className="pt-4">
                    <input
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="500"
                      className="ps-3 pe-2"
                      type="text"
                      placeholder="Language"
                      id="placeholder_boder"
                    />
                  </Col>
                  <Col xs={12} sm={6} className="pt-4">
                    <input
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      data-aos-offset="200"
                      data-aos-delay="600"
                      className="ps-3 pe-2"
                      type="text"
                      placeholder="Category"
                      id="placeholder_boder"
                    />
                  </Col> */}
                </Row>
              </form>

              <button
                data-aos="zoom-in"
                data-aos-duration="900"
                data-aos-offset="200"
                data-aos-delay="700"
                className=" fw_bold fs_base ff_noto primary_btn send_btn my-5"
              >
                Send now
              </button>
            </Col>
            <Col
              // data-aos="fade-left"
              // data-aos-duration="1200"
              // data-aos-offset="200"
              md="6"
            >
              <div className=" d-flex justify-content-end">
                <LazyLoadImage
                  className="w-100"
                  src={workman_us}
                  placeholderSrc={workman_us}
                  alt="workman_us"
                  effect="blur"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactUsTwo;
