import React, { useState } from "react";

const Herosection = () => {
  const [openNav, setopenNav] = useState(true);
  if (openNav) {
    document.body.classList.remove("overflow-hidden");
  } else {
    document.body.classList.add("overflow-hidden");
  }
  return (
    <>
      <section>
        <div data-aos="flip-left" data-aos-duration="1000">
          <div className="hero_background mh_60 d-flex flex-column">
            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 ">
              <div className="container">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h2 className=" ff_oswald fw_bold text-white fs_6xl mb-3">
                    Our Services
                  </h2>
                  <p className="fs_base fw_regular ff_noto text-white mb-0 text-center ">
                    Expertly Providing a Wide Range of Solutions to Meet Your
                    Needs.
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Herosection;
