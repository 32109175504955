import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import PageLogo from "../assets/image/svg/pagelogo.svg";
const ForceNav = () => {
  const [openNav, setopenNav] = useState(true);
  if (openNav) {
    document.body.classList.remove("overflow-hidden");
  } else {
    document.body.classList.add("overflow-hidden");
  }
  return (
    <>
      <nav className="position-relative">
        <Container>
          <div className="d-flex align-items-center justify-content-between">
            <Link to="/" className="d-inline-block page_logo">
              <img className="w-100" src={PageLogo} alt="pagelogo" />
            </Link>
            <ul className="ps-0 mb-0 d-none d-md-flex gap-4 gap-lg-5 align-items-center">
              <li>
                <Link to="/" className="common_para nav_links">
                  Home
                </Link>
              </li>
              <li>
                <a href="#About" className="common_para nav_links">
                  About us
                </a>
              </li>
              <li>
                <div className="dropdown common_para nav_links">
                  <button
                    className="dropdown-toggle d-flex align-items-center bg-transparent border-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Service
                  </button>
                  <ul className="dropdown-menu py-0 bg-white rounded-1">
                    <li>
                      <Link
                        to="/Service"
                        style={{ cursor: "pointer" }}
                        className="dropdown-item common_para px-3 d-inline-block common_para"
                        href="#"
                      >
                        Labour hire
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Service"
                        style={{ cursor: "pointer" }}
                        className="dropdown-item common_para px-3 d-inline-block common_para"
                        href="#"
                      >
                        Workshop
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/Projects" className="common_para nav_links">
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/Contact">
                  <button className="primary_btn ff_noto fw_bold">
                    Contact us
                  </button>
                </Link>
              </li>
            </ul>

            <button
              onClick={() => setopenNav(false)}
              className="d-flex justify-content-evenly flex-column d-md-none menu_btn"
            >
              <span className="menu_line"></span>
              <span className="menu_line"></span>
              <span className="menu_line"></span>
            </button>
          </div>
        </Container>
        <div
          className={
            openNav ? "showNav d-md-none d-block" : "hideNav d-md-none d-block"
          }
        >
          <ul className="ps-0 mb-0 position-relative d-flex gap-5 justify-content-center w-100 min-vh-100 flex-column align-items-center">
            <button
              onClick={() => setopenNav(true)}
              className="btn btn-close fs-4 position-absolute top-0 end-0 mt-4 me-4"
            ></button>
            <li onClick={() => setopenNav(true)}>
              <Link to="/" className="common_para nav_links">
                Home
              </Link>
            </li>
            <li onClick={() => setopenNav(true)}>
              <a href="#About" className="common_para nav_links">
                About us
              </a>
            </li>
            <li>
              <div className="dropdown common_para nav_links">
                <button
                  className="dropdown-toggle d-flex align-items-center bg-transparent border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Service
                </button>
                <ul className="dropdown-menu py-0 bg-white rounded-1">
                  <li>
                    <Link
                      onClick={() => setopenNav(true)}
                      to="/Service"
                      style={{ cursor: "pointer" }}
                      className="dropdown-item common_para px-3 d-inline-block common_para"
                      href="#hire"
                    >
                      Labour hire
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setopenNav(true)}
                      to="/Service"
                      style={{ cursor: "pointer" }}
                      className="dropdown-item common_para px-3 d-inline-block common_para"
                      href="#Workshop"
                    >
                      Workshop
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li onClick={() => setopenNav(true)}>
              <Link to="/Projects" className="common_para nav_links">
                Projects
              </Link>
            </li>
            <li onClick={() => setopenNav(true)}>
              <Link to="/Contact">
                <button className="primary_btn ff_noto fw_bold">
                  Contact us
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default ForceNav;
